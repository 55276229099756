<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    hide-default-footer
    :items-per-page="10000"
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item.uuid">
          <td>
            <router-link
              :to="{ name: 'influencer', params: { id: item.influencer.uuid } }"
            >
              {{ item.influencer.user.first_name }}
              {{ item.influencer.user.last_name }}
            </router-link>
          </td>
          <td class="text-right">{{ item.clicks | local_numbers }}</td>
          <td class="text-right">{{ item.unique_clicks | local_numbers }}</td>
          <td class="text-right">{{ item.sales | local_numbers }}</td>
          <td class="text-right">{{ item.conversion }}</td>
          <td class="text-right">{{ item.value | local_numbers }}</td>
          <td class="text-right">{{ item.commission | local_numbers }}</td>
        </tr>
      </tbody>
    </template>

    <div class="text-center" slot="no-data">
      No data found.
    </div>
  </v-data-table>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: "Influencer",
        value: "influencer.user.first_name",
        sortable: true
      },
      { text: "Clicks", value: "clicks", sortable: true },
      { text: "Unique clicks", value: "unique_clicks", sortable: true },
      { text: "Sales", value: "sales", sortable: true },
      { text: "Conversion rate", value: "conversion", sortable: true },
      { text: "Revenue", value: "value", sortable: true },
      { text: "Commission", value: "commission", sortable: true }
    ]
  }),
  methods: {
    goToCampaign(campaign) {
      this.$router.push({ name: "campaign", params: { id: campaign.uuid } });
    }
  }
};
</script>
